import React, { useState, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'

import Typography from '../components/typography'
import Box from '../components/box'
import IntegrationCard from '../components/integrationCard'

const IntegrationsList = ({ tags: _tags, integrations: _integrations }) => {
  const theme = useTheme()

  const tags = [{ label: 'All', slug: 'all' }, ..._tags]
  const labelBySlug = useMemo(() => {
    const labelBySlug = new Map()
    for (let i = 0; i < tags.length; i++) {
      labelBySlug.set(tags[i].slug, tags[i].label)
    }
    return labelBySlug
  }, [tags])
  const [selectedTags, setSelectedTags] = useState(
    new Set(tags.map(t => t.slug))
  )

  // Transforms an array of integrations to a sorted keyed list based on categorySlug
  // Ex: [{ ..., label: 'google', categorySlugs: ['data']}] =>
  // { data: [{ ..., label: 'google'}]}
  const integrations = useMemo(() => {
    return _integrations.reduce((acc, val) => {
      const categorySlugs = val.node.frontmatter.categorySlugs
      if (!categorySlugs) return acc

      for (let i = 0; i < categorySlugs.length; i++) {
        const slug = categorySlugs[i]
        if (!acc[slug]) {
          acc[slug] = []
        }
        acc[slug].push(val)
      }
      return acc
    }, {})
  }, [_integrations])

  const selectedIntegrations = useMemo(() => {
    return Array.from(selectedTags).reduce((acc, val) => {
      if (val === 'all') return acc
      const newAcc = { ...acc, [val]: integrations[val] }
      return newAcc
    }, {})
  }, [selectedTags, integrations])

  function handleChange(e) {
    if (e.target.value === 'all') {
      if (e.target.checked) {
        setSelectedTags(new Set(tags.map(t => t.slug)))
      } else {
        setSelectedTags(new Set())
      }

      return
    }

    if (e.target.checked) {
      setSelectedTags(new Set(selectedTags.add(e.target.value)))
    } else {
      setSelectedTags(
        new Set([...selectedTags].filter(i => i !== e.target.value))
      )
    }
  }

  return (
    <IntegrationsContainer>
      <TagsContainer>
        {tags.map(tag => (
          <Li key={tag.slug}>
            <label htmlFor={tag.slug}>{tag.label}</label>
            <input
              type="checkbox"
              id={tag.slug}
              value={tag.slug}
              onChange={handleChange}
              checked={selectedTags.has(tag.slug)}
            ></input>
          </Li>
        ))}
      </TagsContainer>
      <IntegrationsListContainer>
        {Object.keys(selectedIntegrations).length > 0 ? (
          Object.keys(selectedIntegrations).map(categorySlug => {
            const label = labelBySlug.get(categorySlug)
            return (
              <CategoryContainer key={categorySlug}>
                <Typography
                  textType="heading-medium"
                  fontWeight="500"
                  style={{ fontFamily: theme.typography.fontFamilyInter }}
                >
                  {label}
                </Typography>
                <Box marginBottom="2rem" />
                <Integrations>
                  {integrations[categorySlug].map(integration => (
                    <IntegrationCard
                      integration={integration.node}
                      selectedTags={selectedTags}
                      key={integration.node.fields.slug}
                    />
                  ))}
                </Integrations>
              </CategoryContainer>
            )
          })
        ) : (
          <Typography colorType="core.content.secondary">
            No integrations found
          </Typography>
        )}
      </IntegrationsListContainer>
    </IntegrationsContainer>
  )
}

const IntegrationsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  flex-direction: column;
  padding-top: 3rem;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
  }
`

const TagsContainer = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  flex-direction: row;
  position: relative;
  overflow-y: auto;
  width: 95vw;
  box-sizing: border-box;

  li {
    border-bottom: none;
    margin-right: 2rem;
  }

  a {
    display: none;
  }

  @media ${props => props.theme.device.tablet} {
    width: 16.25rem;
    flex-direction: column;
    margin-right: 4.6875rem;
    margin-left: 3.125rem;
    position: -webkit-sticky;
    position: sticky;
    top: ${props => props.theme.spacing(8)};
    // Sets the height to "auto" on flex container children
    // https://stackoverflow.com/questions/27575779/prevent-a-flex-items-height-from-expanding-to-match-other-flex-items
    height: 0%;

    a {
      font-family: ${props => props.theme.typography.fontFamilyGTMono};
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #757575;
      text-decoration: none;
      margin-top: 2rem;

      svg {
        margin-left: 0.25rem;
      }
    }
  }
`

const IntegrationsListContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1.25rem;
  padding-top: 2.5rem;
  flex-direction: column;
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
`

const Integrations = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
`

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;

  label {
    font-family: ${props => props.theme.typography.fontFamilyGTMono} !important;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${props => props.theme.core.content.tertiary};

    cursor: pointer;
    flex: 1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    display: flex;
    align-items: center;
  }
`

export default IntegrationsList
