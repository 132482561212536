import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import RowLayout from '../../components/rowLayout'
import Footer from '../../components/footer'
import Box from '../../components/box'
import Hidden from '../../components/hidden'
import CompanyIcons from '../../components/companyIcons'

import IntegrationsList from '../../containers/integrationsList'
import { H1, CopyBodyH2, Blue, Yellow, Purple } from '../../pages/index'
import HomePatternSvg from '../../assets/svg/home/home-pattern-one.svg'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'
import Button from '../../components/button'

const IntegrationsListingTemplate = props => {
  const { pageContext } = props
  const { integrations, tags = [] } = pageContext

  useRemoveHotjarScript()

  return (
    <>
      <SEO
        title="Integrate the tools that you already love - Pulse"
        description="Pull, push, and auto-sync data from the arsenal that you already have to centralize information, ease onboarding, and enrich the current profiles."
        ogImage="/assets/ogimages/pulse-integrations.png"
        twImage="/assets/ogimages/twitter/pulse-integrations.png"
      />
      <Nav />
      <HomePatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      />
      <Banner>
        <RowLayout>
          <Box style={{ zIndex: 2, position: 'relative' }}>
            <H1 style={{ width: '66.315rem', maxWidth: '100%' }}>
              Integrated with the tools that you already use
            </H1>
            <Button onClick={() => navigate('/signup')}>
              Get Pulse For Free
            </Button>
            <p id="hero-subtext">Create your workspace in {'<'}2min</p>
            <CopyBodyH2
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                width: '95%',
              }}
            >
              Seamlessly pull, push, and auto-sync data from the arsenal that
              you already have to <Blue>centralize information</Blue>,{' '}
              <Purple>ease onboarding</Purple>, and{' '}
              <Yellow>enrich current profiles</Yellow> of everyone on the team.
            </CopyBodyH2>
            <Hidden widthDown={600}>
              <CompanyIcons />
            </Hidden>
          </Box>
        </RowLayout>
      </Banner>
      <IntegrationsListContainer>
        <IntegrationsList integrations={integrations} tags={tags} />
      </IntegrationsListContainer>
      <Footer />
    </>
  )
}

const Banner = styled.div`
  width: 100%;
  min-height: 40.75rem;
  max-height: max-content;
  background-color: black;
  background-size: cover;
  padding-bottom: 2rem;
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`

const IntegrationsListContainer = styled.div`
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(12)};
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  margin: auto;

  @media ${props => props.theme.device.laptopL} {
    max-width: 87.5rem;
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
  }
`

export default IntegrationsListingTemplate
